'use strict';

window.GUI = function (GUI) {
    class NoSpotifyAccountView extends GUI.BackgroundView {
        constructor(control) {
            super(Icon.AudioZone.SPOTIFY_ICON, _("audio-server.spotify.no-account"), _("audio-server.settings.spotify.info-text", {
                productName: _("audio-server")
            }), _("media.preferences.services.editor.add-spotify-account"), () => {
                this.control.audioserverComp.checkForOutdatedLoginFlowFirmware(this.hideButton.onButtonReleased.bind(this), 'Spotify').then(isUpToDate => {
                    if (isUpToDate) {
                        Controls.AudioZoneV2Control.SingleTones.SpotifyAccountManager.shared(control).showAddSpotifyScreen();
                    }
                });
            }, true);
            this.control = control;
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(function () {
                this.elements.hideButton = $("<div class='texts__hide-button'>" + _("audio-server.spotify.hide") + "</div>");
                this.element.find(".background-view__texts").append(this.elements.hideButton);
                this.hideButton = new GUI.LxButton(this, this.elements.hideButton, Color.BUTTON_GLOW, null, true);
                this.hideButton.useElementAsActivePart();
                this.addToHandledSubviews(this.hideButton);

                this.hideButton.onButtonReleased = function () {
                    var prms;
                    Controls.AudioZoneV2Control.SingleTones.CustomizationManager.shared(this.control).set(MusicServerEnum.ControlContentMenuId.SPOTIFY, {
                        enabled: false
                    });
                    Controls.AudioZoneV2Control.SingleTones.CustomizationManager.shared(this.control).set(MusicServerEnum.ControlContentMenuId.SPOTIFY_PLAYLISTS, {
                        enabled: false
                    });

                    if (HD_APP) {
                        prms = Controls.AudioZoneV2Control.SingleTones.ControlContentMenu.shared(this.control).getInitialHDDetailedShowStateArgs().then(function (args) {
                            return this.ViewController.showState.apply(this.ViewController, args);
                        }.bind(this));
                    } else {
                        prms = this.ViewController.navigateBack();
                    }
                };
            }.bind(this));
        }

    }

    GUI.NoSpotifyAccountView = NoSpotifyAccountView;
    return GUI;
}(window.GUI || {});
