'use strict';

window.GUI = function (GUI) {
    class NoSoundsuitAccountView extends GUI.BackgroundView {
        constructor(control) {
            super(Icon.AudioZone.SOUNDSUIT, _("audio-server.soundsuit.no-account"), null, _("audio-server.soundsuit.add-account"), () => {
                Controls.AudioZoneV2Control.SingleTones.SoundsuitAccountManager.shared(control).showAddSoundsuitScreen();
            }, true);
            this.control = control;
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(function () {
                this.elements.hideButton = $("<div class='texts__hide-button'>" + _("audio-server.soundsuit.hide") + "</div>");
                this.element.find(".background-view__texts").append(this.elements.hideButton);
                this.hideButton = new GUI.LxButton(this, this.elements.hideButton, Color.BUTTON_GLOW, null, true);
                this.hideButton.useElementAsActivePart();
                this.addToHandledSubviews(this.hideButton);

                this.hideButton.onButtonReleased = function () {
                    var prms;
                    Controls.AudioZoneV2Control.SingleTones.CustomizationManager.shared(this.control).set(MusicServerEnum.ControlContentMenuId.SOUNDSUIT, {
                        enabled: false
                    });

                    if (HD_APP) {
                        prms = Controls.AudioZoneV2Control.SingleTones.ControlContentMenu.shared(this.control).getInitialHDDetailedShowStateArgs().then(function (args) {
                            return this.ViewController.showState.apply(this.ViewController, args);
                        }.bind(this));
                    } else {
                        prms = this.ViewController.navigateBack();
                    }
                };
                this.control.audioserverComp.checkForOutdatedLoginFlowFirmware(this.hideButton.onButtonReleased.bind(this), 'Soundsuit');
            }.bind(this));
        }

    }

    GUI.NoSoundsuitAccountView = NoSoundsuitAccountView;
    return GUI;
}(window.GUI || {});
